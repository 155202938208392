var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "pages.admin.AdminAnnouncementCreate.title",
                expression: "'pages.admin.AdminAnnouncementCreate.title'"
              }
            ]
          }),
          _c(
            "CCardBody",
            [
              _c("ValidatedSelect", {
                attrs: {
                  field: _vm.$v.message.roleType,
                  "data-cy": "roleType-field",
                  value: _vm.$v.message.roleType.$model,
                  translationKey: "message.roleType",
                  options: _vm.roleTypeOptions
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.message.roleType, "$model", $event)
                  }
                }
              }),
              _c("ValidatedInput", {
                attrs: {
                  field: _vm.$v.message.subject,
                  "data-cy": "subject-field",
                  value: _vm.$v.message.subject.$model,
                  translationKey: "message.subject"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.message.subject, "$model", $event)
                  }
                }
              }),
              _c("ValidatedTextArea", {
                attrs: {
                  field: _vm.$v.message.body,
                  value: _vm.$v.message.body.$model,
                  "data-cy": "body-field",
                  rows: "5",
                  translationKey: "message.body"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.message.body, "$model", $event)
                  }
                }
              }),
              _c(
                "CButton",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    color: "primary",
                    "data-cy": "submit",
                    disabled: _vm.sending
                  },
                  on: { click: _vm.handle }
                },
                [
                  _vm.sending ? _c("CSpinner") : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("pages.admin.AdminAnnouncementCreate.sendButton")
                      ) +
                      " "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }